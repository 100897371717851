import React from "react";
import { withTranslation } from "react-i18next";

class Header extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <div class="container">
          <div className="logo-wrapper">
            <img src="logo_beside.png" alt="" />
          </div>
        </div>
        {/* <h1>{t("hello_welcome_to_react")}</h1> */}
      </>
    );
  }
}
export default withTranslation()(Header);

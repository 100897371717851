import React, { useState, useEffect } from "react";
// import Axios from "axios";
import "./App.css";
// import { FaSearch } from "react-icons/fa";
// import { FcSpeaker } from "react-icons/fc";
// import dictData from "./data.json";
import data from "./data_en.json";
// import dataIt from "./data_it.json";
// import { withTranslation } from "react-i18next";
import Header from "./components/Header";
import LanguageSelect from "./components/languageSelect";
import { useTranslation } from "react-i18next";
// import i18next from "i18next";

// import Filter from "./components/Filter";

function App() {
  const [word, setWord] = useState("");
  const [filter, setFilter] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [active, setActive] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(null);

  const { t } = useTranslation();

  const filterData = (event) => {
    // let keyword = event.target.value;
    let buttons = document.querySelectorAll(".filter-button");
    // this.setState({ filtredData: keyword });
    setFilter(event.target.value);
    // buttons.classList.remove("active");
    // console.log(event.target.value);
    buttons.forEach((element) => {
      if (element.classList.contains("active")) {
        element.classList.remove("active");
      }
    });
    event.target.classList.toggle("active");

    return filter;
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handlePopup = (item) => {
    setActive(item);
    document.querySelector(".overlay").classList.add("is-active");
    // const body = document.body;
    // body.style.position = 'fixed';
    // body.style.top = `-${scrollPosition}`;
  };
  const handleClosePopup = () => {
    setActive(false);
    document.querySelector(".overlay").classList.remove("is-active");

    // const body = document.body;
    // body.style.position = '';
    // body.style.top = '';
    // window.scrollTo(0, parseInt(scrollPosition || "0") * -1);
  };

  function clearSearch() {
    setSearchResults(items);
    setWord("");
    document.getElementById("search-input").classList.remove("has-value");
  }

  function checkSearchValue() {
    if (word.length > 0) {
      document.getElementById("search-input").classList.add("has-value");
    } else {
      document.getElementById("search-input").classList.remove("has-value");
    }
  }

  const createMarkup = (html) => {
    return { __html: html };
  };

  const items = data;

  useEffect(() => {
    // if (i18next.language == "en") {
    //   const items = dataEn;
    //   console.log("angol");
    // }

    // if (i18next.language == "it") {
    //   const items = dataIt;
    // }
    function filterItems() {
      const filterItems = items
        .filter((item) => {
          if (filter == null) return item;
          else if (item.area.toLowerCase().includes(filter.toLowerCase())) {
            return item;
          }
        })
        .filter((item) => {
          if (word == null) return item;
          else if (word.length < 3) return item;
          else if (
            item.definition.toLowerCase().includes(word.toLowerCase()) ||
            item.generalDef.toLowerCase().includes(word.toLowerCase())
          ) {
            return item;
          }
        })
        .map((item) => {
          if (word !== null) {
            let newDefinition = item.definition.replace(
              new RegExp(word, "gi"),
              (match) => `<mark>${match}</mark>`
            );
            let newGeneralDef = item.generalDef.replace(
              new RegExp(word, "gi"),
              (match) => `<mark>${match}</mark>`
            );
            return {
              ...item,
              definition: newDefinition,
              generalDef: newGeneralDef,
            };
          }
        });

      setSearchResults(filterItems);
    }
    filterItems();
  }, [word, filter]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <header>
          <div className="nav-container">
            <Header></Header>

            <div className="container">
              <nav className="navigation">
                <div className="buttons">
                  <button
                    className="filter-button active"
                    onClick={filterData}
                    value=""
                  >
                    {t("all")}
                  </button>
                  <button
                    className="filter-button"
                    onClick={filterData}
                    value="poiesis"
                  >
                    {t("poiesis")}
                  </button>
                  <button
                    className="filter-button"
                    onClick={filterData}
                    value="sculpture"
                  >
                    {t("sculpture")}
                  </button>
                  <button
                    className="filter-button"
                    onClick={filterData}
                    value="graphic"
                  >
                    {t("graphic")}
                  </button>
                  <button
                    className="filter-button"
                    onClick={filterData}
                    value="drawing"
                  >
                    {t("drawing")}
                  </button>
                  <button
                    className="filter-button"
                    onClick={filterData}
                    value="painting"
                  >
                    {t("painting")}
                  </button>
                </div>
                {/* <LanguageSelect></LanguageSelect> */}
                <div className="languages">
                  <ul>
                    <li>
                      <a
                        href="https://dictionary.eu4art.eu"
                        style={{ backgroundColor: "#000", color: "#fff" }}
                      >
                        EN
                      </a>
                    </li>
                    <li>
                      <a href="https://dictionary.eu4art.eu/hu">HU</a>
                    </li>
                    <li>
                      <a href="https://dictionary.eu4art.eu/de">DE</a>
                    </li>
                    <li>
                      <a href="https://dictionary.eu4art.eu/it">IT</a>
                    </li>
                    <li>
                      <a href="https://dictionary.eu4art.eu/lv">LV</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>

        <main className="content">
          <div className="container">
            <div className="search-wrapper">
              <div className="input-wrapper">
                <input
                  type="text"
                  // placeholder="Search..."
                  onChange={(e) => setWord(e.target.value)}
                  onKeyUp={(e) => checkSearchValue()}
                  value={word}
                  className="search-input"
                  id="search-input"
                />
                <div className="close-button" onClick={() => clearSearch()}>
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 1L30.5 30.5M1 30.5L30.5 1" stroke="#040606" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="results-wrapper">
              {searchResults.map((item, index) => (
                <div
                  key={index}
                  // onClick={() => setActive(item)}
                  className={`result-item ${active === item && "active"}`}
                >
                  <div className="result-item-inner">
                    <h3
                      className="definition"
                      dangerouslySetInnerHTML={createMarkup(item.definition)}
                      onClick={() => handlePopup(item)}
                    ></h3>
                    <div className="content">
                      <div className="data">
                        <div className="base-data">
                          <p className="area">
                            <strong>Area:</strong> {item.area}
                          </p>
                          <p className="type">
                            <strong>Type:</strong> {item.type}
                          </p>
                          <p className="number">
                            <strong>Number:</strong> {item.number}
                          </p>
                          <p className="gender">
                            <strong>Gender:</strong> {item.gender}
                          </p>
                          <p className="category">
                            <strong>Category:</strong> {item.category}
                          </p>
                          <p className="synonym">
                            <strong>Synonym:</strong> {item.synonym}
                          </p>
                        </div>
                        <div className="translations">
                          <p className="italian">{item.italian}</p>
                          <p className="italian">{item.german}</p>
                          <p className="italian">{item.latvian}</p>
                          <p className="italian">{item.hungarian}</p>
                        </div>
                        <div className="general-definition">
                          <h3>General definition:</h3>
                          <p
                            className="generalDef"
                            dangerouslySetInnerHTML={createMarkup(
                              item.generalDef
                            )}
                          ></p>
                        </div>
                        <p className="example">
                          <strong>Example:</strong> {item.example}
                        </p>
                      </div>
                      <div className="image-wrapper">
                        <img
                          className={item.image ? "image" : "image hidden"}
                          src={"./images/" + item.image}
                          alt=""
                        />
                        <p className="worktitle">{item.workTitle}</p>
                      </div>
                    </div>
                    <button onClick={() => handleClosePopup(false)}>
                      <svg
                        width="31"
                        height="31"
                        viewBox="0 0 31 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L30.5 30.5M1 30.5L30.5 1"
                          stroke="#040606"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>

        <div className="yellow-box box"></div>
        <div className="red-box box"></div>
        <div className="green-box box"></div>
        <div className="blue-box box"></div>

        <div className="overlay"></div>
      </div>
    </>
  );
}

export default App;
